<template>
	<div class="app-container">
		<el-card>
			<div class="info">
				<span>姓名/学号：</span>
				<el-select v-model="studentInfo" filterable remote reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethod" @change="studentChange" class="w180">
					<el-option v-for="item in searchStudentList" :key="item.id" :value="item.id" :label="item.nameNum"> </el-option>
				</el-select>
				<span>时间：</span>
				<div class="block">
					<el-date-picker v-model="searchDate" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" @change="pickchange"> </el-date-picker>
				</div>
				<div>
					<el-button type="primary" @click="goSearch">查询</el-button>
				</div>
			</div>
			<div class="flex-row">
				<!-- 云图 -->
				<div class="wordcloud" id="wordcloud" v-show="ifShow_wordcloud"></div>
				<div class="wordcloud" id="wordcloud_none" v-show="ifShow_wordcloud == false">
					<div style="text-align:center;font-size:15px;color:gray;padding-top:150px;">暂无云词数据</div>
				</div>
				
				<!-- 学生数据信息 -->
				<div class="stucard" v-if="this.studentItem.id > 0">
					<div class="leftPhoto">
						<img class="img1" v-if="studentItem.photo != null && studentItem.photo.length > 0" :src=" imgBaseUrl + studentItem.photo" alt="" />
						<img class="img1" v-else src="../../../src/assets/nopic.png" alt="" />
					</div>
					<div class="rightInfo">
						<el-row :gutter="20" class="mr0">
							<el-col :span="12">
								<div class="grid-content">姓名：{{ studentItem.name }}</div>
							</el-col>
							<el-col :span="12">
								<div class="grid-content">总得分：{{ studentItem.fractionnum }} 分</div>
							</el-col>
						</el-row>
						<el-row :gutter="20" class="mr0">
							<el-col :span="12">
								<div class="grid-content">当月总加分：{{ studentItem.totalBonus }}</div>
							</el-col>
							<el-col :span="12">
								<div class="grid-content">当月总扣分：{{ studentItem.totalDeduction }} 分</div>
							</el-col>
						</el-row>
						<el-row :gutter="20" class="mr0">
							<el-col :span="12">
								<div class="grid-content">学号：{{ studentItem.studentnum }}</div>
							</el-col>
							<el-col :span="12">
								<div class="grid-content">性别：{{ studentItem.sex }}</div>
							</el-col>
						</el-row>
						<el-row :gutter="20" class="mr0">
							<el-col :span="12">
								<div class="grid-content">院系：{{ studentItem.dname }}</div>
							</el-col>
							<el-col :span="12">
								<div class="grid-content">班级：{{ studentItem.cname }}</div>
							</el-col>
						</el-row>
						<el-row :gutter="20" class="mr0">
							<el-col :span="12">
								<div class="grid-content">班号：{{ studentItem.classnum }}</div>
							</el-col>
							<el-col :span="12">
								<div class="grid-content">宿舍楼：{{ studentItem.dorname }}</div>
							</el-col>
						</el-row>
						<el-row :gutter="20" class="mr0">
							<el-col :span="12">
								<div class="grid-content">宿舍号：{{ studentItem.dormitorynum }}</div>
							</el-col>
							<el-col :span="12">
								<div class="grid-content">床号：{{ studentItem.bednum }}</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
			
			<!-- 统计图 -->
			<div class="mgt24 align-center" v-if="changeArea">
				<el-button-group>
					<el-button v-for="(item, index) in tab" :key="index" :class="index == current ? 'active' : ''" @click="() => tabs(index)">{{ item }}</el-button>
				</el-button-group>
			</div>
			<div class="echarts">
				<!-- 雷达图 -->
				<div class="test3">
					<div id="myChart3" class="wordcloud" style="width: 100%; height: 300px" v-show="isShowRadarChart"></div>
					<div class="wordcloud" style="width: 100%; height: 300px" v-show="isShowRadarChart == false">
						<div style="text-align:center;font-size:15px;color:gray;padding-top:150px;">暂无雷达图数据</div>
					</div>
				</div>
				<!-- 折线图 -->
				<div class="test2">
					<div id="myChart2" v-show="ifShow_line_chart" style="width: 100%; height: 320px"></div>
					<div v-show="ifShow_line_chart == false" style="text-align:center;font-size:15px;color:gray;padding-top:150px; width: 100%; height: 320px">暂无折线图数据</div>
				</div>
			</div>

			<el-dialog v-if="dialogFormVisible" :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :before-close="handleClose" width="50%" minWidth="600px">
				<addComponent ref="dialogForm" @changeDialogFormVisible="changeDialogFormVisible" :dialog-status="dialogStatus" :dialogStatus="dialogStatus" />
			</el-dialog>

		</el-card>
	</div>
</template>

<script>
	import * as api from "@/api/users";
	import {
		getHttpUrl
	} from "@/utils/request";
	import * as echarts from "echarts";
	import addComponent from "./addComponent.vue";
	export default {
		name: "StudentMap",
		components: {
			addComponent,
		},
		data() {
			return {
				imgBaseUrl: getHttpUrl(),
				studentNameOptions: [], //学生姓名集合
				studentName: "", // 学生姓名
				studentNum: "", // 学生学号
				studentId: 0, // 学生id

				myChart2: null, // 拆线图
				myChart3: null, // 雷达图
				indicator: [],
				page: 1,
				pageSize: 10,
				total: 1000,
				did: 1,
				value1: "",
				month: {},
				threeMonth: {},
				oneYear: {},
				current: 0,
				tab: ["日", "周", "月"],
				systemList: [],
				searchDate: [],
				form: {},
				infoForm: {},
				idis: "",
				formLabelWidth: "120px",
				Authorization: "",
				dialogFormVisible: false,
				dialogStatus: "",
				textMap: {
					update: "编辑",
					create: "新增",
					view: "详情",
				},

				listQuest: {
					page: 1,
					pageSize: 10,
				},
				roleList: [],
				xDataList: [],
				yDataList: [],
				options: [{
						label: "有",
						value: 1,
					},
					{
						label: "没有",
						value: 0,
					},
				],
				roles: [],
				tableData: [],
				input: "",
				depid: "",
				
				namelist: [],
				dataline: [],
				xdatelist: [],

				// 云词
				wordcloudList: [],
				// 是否显示 云词
				ifShow_wordcloud: true,
				// 云词 obj
				wordcloud_chart: null,
				
				// 学生雷达图
				isShowRadarChart:true,
				ifShow_line_chart:true,

				// 找到的 学员
				studentItem: {},

				// 搜索条件
				studentInfo: "",
				searchStudentList: [], //搜索学生列表

				changeArea: false, //日周月显示
			};
		},
		computed: {
			isStudent() {
				return JSON.parse(sessionStorage.getItem("userinfo")).roleid === 7;
			},
			// 折线图
			getOption2() {
				let that = this;
				let option = {
					title: {
						text: "", // 主标题
						subtext: "", // 副标题
						x: "left", // x轴方向对齐方式
					},
					tooltip: {
						trigger: "axis", // axis   item   none三个值
					},
					xAxis: {
						type: "category",
						data: that.xdatelist[0],
						name: "",
						axisLabel: {
							interval: 0,
							rotate: 38,
						},
					},
					yAxis: {
						type: "value",
						name: "",
					},
					legend: {
						orient: "horizontal",
						x: "center",
						y: "bottom",
						// bottom:0,
						data: that.namelist,
					},
					series: that.dataline,
				};
				return option;
			},
			
			// 雷达图
			getOption3() {
				let that = this;
				let option = {
					legend: {
						data: ["基准数据", "实际数据"],
						top: 0,
					},
					radar: {
						indicator: that.indicator,
						radius: 112, // 图大小
						name: {
							textStyle: {
								padding: [-15, -15], // 控制文字padding
							},
						},
					},
					series: [{
						type: "radar",
						lineStyle: {},
						data: [{
							value: that.ydata, //[0,0,0,0,]
							name: "基准数据",
							lineStyle: {
								color: 'rgba(84, 112, 198, .5)', // 线条颜色
								type: 'dashed', // 线条类型
							},
						}, {
							value: that.xdata, //真实数据
							name: "实际数据",
							label: {
								normal: {
									show: true,
									formatter: function(params) {
										return params.xdata;
									},
								},
							},
						}, ],
					}, ],
				};
				return option;
			},
		},
		created() {
			this.getType();
		},
		methods: {
			// 智能 搜索 学员
			getStudentList(val) {
				api.selectBySearchName({
					searchName: val,
					searchType:'1'
				}).then((res) => {
					if (res.status === 1) {
						let arr = res.data.data;
						if (arr != null) {
							arr.forEach((res) => {
								res.nameNum = res.num + "  " + res.name;
							});
							this.searchStudentList = arr;
						}
					}
				});
			},
			// 姓名/学号
			remoteMethod(query) {
				this.getStudentList(query); // 调用上面的方法
			},
			studentChange(id) {
				this.studentId = id;
			},

			// 词云
			showWordCloud(params) {
				if (this.studentId > 0) {
					api.groupByStudent(params).then((res) => {
						if (res.status === 1) {
							this.wordcloudList = res.data;
							this.wordcloud();
						} else {
							this.wordcloud_chart.clear();
						}
					});
				}
			},
			wordcloud() {
				// 基于准备好的dom，初始化echarts实例
				var objTemp = document.getElementById("wordcloud");
				if (this.wordcloud_chart == null) {
					this.wordcloud_chart = echarts.init(objTemp);
				}
				if (this.wordcloudList != null && this.wordcloudList.length > 0) {
					this.ifShow_wordcloud = true;
					// 绘制图表
					this.wordcloud_chart.setOption({
						backgroundColor: "#ffffff",
						tooltip: {
							show: true,
						},
						series: [{
							name: "热点分析", //数据提示窗标题
							type: "wordCloud",
							drawOutOfBound: true,
							sizeRange: [12, 50], //画布范围
							rotationRange: [0, 0], //数据翻转范围
							shape: "circle", //形状
							textPadding: 0,
							autoSize: {
								enable: true,
								minSize: 6,
							},
							textStyle: {
								color: () => {
									return (
										"rgb(" + [
											Math.round(Math.random() * 255),
											Math.round(Math.random() * 255),
											Math.round(Math.random() * 255),
										].join(",") +
										")"
									);
								},
								emphasis: {
									shadowBlur: 10,
									shadowColor: "#333",
								},
							},
							data: this.wordcloudList,
						}, ],
					});
				} else {
					this.ifShow_wordcloud = false;
				}
			},
			// 模糊搜索学生姓名
			// getStudentNameList() {
			//   api
			//     .selectByUser({
			//       name: "",
			//     })
			//     .then((res) => {
			//       if (res.status === 1) {
			//         this.studentNameOptions = res.data;
			//         console.log(this.$route.query);
			//         if (this.$route.query.studentid) {
			//           this.studentId = this.$route.query.studentid;
			//           this.studentName = this.$route.query.studentname;
			//         } else {
			//           this.studentId = res.data[0].id;
			//           this.studentName = res.data[0].name;
			//         }
			//         this.goSearch();
			//       } else {
			//         this.$message.error(res.msg);
			//       }
			//     });
			// },
			// // 学生姓名改变触发
			// studentNameChange(val) {
			//   this.studentId = val;
			// },
			
			// 雷达图数据
			studentRadar() {
				let me = this;
				let params = {
					startTime: me.searchDate != null && me.searchDate.length > 0 ? me.$moment(me.searchDate[0]).format("YYYY-MM-DD") : "",
					endTime: me.searchDate != null && me.searchDate.length > 0 ? me.$moment(me.searchDate[1]).format("YYYY-MM-DD") : "",
					studentId: me.studentId,
					studentNum: me.studentNum,
				};
				api.studentRadar(params).then((res) => {
					if (res.status == 1) {
						if (res.data.length == 0) {
							// 清除上一次echart留下的缓存的数据
							me.myChart3 && me.myChart3.clear();
							me.isShowRadarChart = false;
						} else if (res.data.length > 0) {
							me.isShowRadarChart = true;
							let arr = [];
							let xdata = [];
							let ydata = [];

							res.data.forEach((item) => {
								xdata.push(item.score);
								ydata.push(0);
								let obj = {
									name: item.category,
									max: 50,
									min: -50,
								};
								arr.push(obj);
							});
							me.indicator = arr;
							me.xdata = xdata;
							me.ydata = ydata;
							me.drawLineRadar(); // 雷达图
						}
					}
				});
			},
			// 日 折线图
			getDataday() {
				let me = this;
				let params = {
					startTime: me.searchDate != null && me.searchDate.length > 0 ?
						me.$moment(me.searchDate[0]).format("YYYY-MM-DD") : "",
					endTime: me.searchDate != null && me.searchDate.length > 0 ?
						me.$moment(me.searchDate[1]).format("YYYY-MM-DD") : "",
					studentId: me.studentId,
					studentNum: me.studentNum,
				};
				api.getstudentDay(params).then((res) => {
					// if (res.status === -1 || res.data.length === 0) {
					// 	me.myChart2 && me.myChart2.clear();
					// 	// me.changeArea = false;
					// 	this.ifShow_line_chart = false;
					// 	return;
					// }
					// me.changeArea = true;
					let datas = res.data;
					let arr = [];
					for (let i = 0; i < datas.length; i++) {
						let line = (datas[i].type = "line");
						let dataList = (datas[i].data = datas[i].dataList);
						let cname = (datas[i].name = datas[i].cname);
						let dateList = (datas[i].xdate = datas[i].dateList);
						let obj = {
							type: line,
							data: dataList,
							name: cname,
							xdate: dateList,
						};
						arr.push(obj);
					}
					let list = [];
					arr.forEach((item) => {
						list.push(item.name);
					});
					let xlist = [];
					arr.forEach((item) => {
						xlist.push(item.xdate);
					});
					me.dataline = arr;
					me.namelist = list;
					me.xdatelist = xlist;
					me.drawLine1(); // 折线图
				});
			},
			// 周
			getDataweek() {
				let params = {
					startTime: "",
					endTime: "",
					studentId: this.studentId,
					studentNum: this.studentNum,
				};
				api.getstudentWeek(params).then((res) => {
					let datas = res.data;
					let arr = [];
					for (let i = 0; i < datas.length; i++) {
						let line = (datas[i].type = "line");
						let dataList = (datas[i].data = datas[i].dataList);
						let cname = (datas[i].name = datas[i].cname);
						let dateList = (datas[i].xdate = datas[i].dateList);
						let obj = {
							type: line,
							data: dataList,
							name: cname,
							xdate: dateList,
						};
						arr.push(obj);
					}
					let list = [];
					arr.forEach((item) => {
						list.push(item.name);
					});
					let xlist = [];
					arr.forEach((item) => {
						xlist.push(item.xdate);
					});
					this.dataline = arr;
					this.namelist = list;
					this.xdatelist = xlist;
					this.drawLine1(); // 折线图
				});
			},
			// 月
			getDatamonth() {
				let params = {
					startTime: "",
					endTime: "",
					studentId: this.studentId,
					studentNum: this.studentNum,
				};
				api.getstudentMonth(params).then((res) => {
					let datas = res.data;
					let arr = [];
					for (let i = 0; i < datas.length; i++) {
						let line = (datas[i].type = "line");
						let dataList = (datas[i].data = datas[i].dataList);
						let dname = (datas[i].name = datas[i].dname);
						let dateList = (datas[i].xdate = datas[i].dateList);
						let obj = {
							type: line,
							data: dataList,
							name: dname,
							xdate: dateList,
						};
						arr.push(obj);
					}
					let list = [];
					arr.forEach((item) => {
						list.push(item.name);
					});
					let xlist = [];
					arr.forEach((item) => {
						xlist.push(item.xdate);
					});
					this.dataline = arr;
					this.namelist = list;
					this.xdatelist = xlist;
					this.drawLine1();
				});
			},
			pickchange(e) {
				this.searchDate = e;
			},
			tabs(index) {
				this.current = index;
				if (this.current == 0) {
					this.getDataday();
				} else if (this.current == 1) {
					this.getDataweek();
				} else {
					this.getDatamonth();
				}
			},
			// 绘制 雷达图
			drawLineRadar() {
				let me = this;
				// 基于准备好的dom，初始化echarts实例
				var objTemp = document.getElementById("myChart3");
				if (me.myChart3 == null) {
					me.myChart3 = echarts.init(objTemp);
				}
				// 绘制图表（雷达图）
				me.myChart3.setOption(me.getOption3);
			},
			drawLine1() {
				let me = this;
				// 基于准备好的dom，初始化echarts实例
				var objTemp = document.getElementById("myChart2");
				// 如果不存在，就进行初始化
				if (me.myChart2 == null) {
					me.myChart2 = echarts.init(objTemp);
				}
				// if (me.namelist.length > 0) {
				// 	// 绘制图表（折线图）
				// 	me.myChart2.setOption(me.getOption2);
				// } else {
				// 	objTemp.innerHTML = "<div style='text-align:center;font-size:15px;color:gray;padding-top:150px;'>暂无走势图数据</div>";
				// }
				
				if (this.namelist.length > 0) {
					this.ifShow_line_chart = true;
					// 绘制图表
					window.onresize = this.myChart2.resize;
					this.myChart2.setOption(this.getOption2);
				} else {
					this.ifShow_line_chart = false;
				}
			},
			// 关闭弹窗
			changeDialogFormVisible(data) {
				this.dialogFormVisible = data;
			},
			getType() {
				api.getCurrUserItem().then((res) => {
					this.idis = res.data.rname;
				});
			},
			handleClose(done) {
				this.$confirm("确认关闭？")
					.then(() => {
						done();
					})
					.catch(() => {});
			},
			// 查询
			goSearch() {
				let me = this;
				let starttime = me.searchDate.length ? me.$moment(me.searchDate[0]).format("YYYY-MM-DD") : "";
				let endtime = me.searchDate.length ? me.$moment(me.searchDate[1]).format("YYYY-MM-DD") : "";
				me.showWordCloud({
					startTime: starttime,
					endTime: endtime,
					studentId: me.studentId,
					studentNum: me.studentNum,
				});
				// 查找学生信息
				api.searchStudent(me.studentId).then((res) => {
					me.studentItem = res.data;
					me.changeArea = true;
					me.$nextTick(() => {
						me.getDataday();
						me.studentRadar();
					});
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	/deep/ .el-table {
		max-height: 600px;
		overflow-y: auto;
	}

	.tabs {
		width: 200px;
		// background: red;
		margin: 20px auto;
		height: 40px;
		line-height: 40px;
		font-size: 20px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		justify-items: center;
		text-align: center;
		cursor: pointer;
	}

	.tabs div {
		width: 40px;
		height: 40px;
	}

	.active {
		background: #409eff;
		color: #fff;
	}

	/deep/.el-input {
		width: 150px;
	}

	/deep/ .el-container {
		width: 100%;
		height: 100% !important;
	}

	.info {
		width: 100%;
		display: flex;

		span {
			line-height: 41px;
		}
	}

	/deep/.el-dialog__title {
		display: flex;
		flex-direction: row;
	}

	/deep/.el-dialog__body {
		padding: 0px;
	}

	.gutter {
		width: 200px;
	}

	.echarts {
		width: 100%;
	}

	.echarts .test2 {
		float: left;
		width: 60%;
		height: 320px;
	}

	.echarts .test3 {
		float: left;
		width: 40%;
		height: 320px;
	}

	.stucard {
		margin-left: 10px;
		width: 560px;
		min-width: 560px;
		height: 22.0625rem;
		border-top: 1px solid gray;
		border-left: 1px solid gray;

		// border-top-left-radius:8px;
		// border-bottom-left-radius:8px;
		.leftPhoto {
			float: left;
			width: 180px;
			height: 22.0625rem;
			border-bottom: 1px solid gray;
			border-right: 1px solid gray;

			// border-bottom-left-radius:8px;
			.img1 {
				width: 152px;
				height: auto;
				margin: 18px auto;
				display: block;
			}
		}

		.rightInfo {
			float: right;
			width: 370px;
			height: 300px;

			.el-row {
				margin-left: 0;
				margin-right: 0;
			}

			.el-col {
				border-bottom: 1px solid gray;
				border-right: 1px solid gray;
				margin: 0;
			}

			.grid-content {
				height: 42px;
				line-height: 20px;
				padding: 8px;
				vertical-align: middle;
			}

			.row-bg {
				background-color: #f9fafc;
			}
		}
	}
</style>
