<template>
  <div>
    <el-row :gutter="1">
      <el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }">
        <!-- :lg="{ span: 10 }"
        :xl="{ span: 24 }" -->
        <hr />
        <el-form
          ref="form"
          :model="form"
          :inline="true"
          label-position="center"
          label-width="100px"
        >
          <div class="boxform">
            <div class="div1">
              <el-form-item label="姓名：" prop="name">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="角色：" prop="rname">
                <el-select v-model="form.rname" placeholder="请选择">
                  <el-option
                    v-for="item in roleList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="学号：" prop="studentnum">
                <el-input v-model="form.studentnum"> </el-input>
              </el-form-item>
              <el-form-item label="职务：" prop="company">
                <el-input v-model="form.company"></el-input>
              </el-form-item>
              <el-form-item label="职称：" prop="companyname">
                <el-input v-model="form.companyname"></el-input>
              </el-form-item>
              <el-form-item label="教龄：" prop="teachage">
                <el-input v-model="form.teachage"></el-input>
              </el-form-item>
              <el-form-item label="民族：" prop="nation">
                <el-input v-model="form.nation"></el-input>
              </el-form-item>
              <el-form-item label="身份证：" prop="pid">
                <el-input v-model="form.pid"></el-input>
              </el-form-item>
              <el-form-item label="密码：" prop="password">
                <el-input v-model="form.password"></el-input>
              </el-form-item>
            </div>
            <div class="div2">
              <!-- <div class="imgphoto"> -->
              <el-form-item class="itemimg" label="照片：" prop="photo">
                <img
                  v-if="dialogStatus != 'create' ? true : false"
                  class="img1" @click="toSelectAddPhotoImg();"
                  :src="imgBaseUrl + form.photo"
                  alt="" />
                <el-upload ref="myUploadAddPhoto"
                  v-if="dialogStatus == 'create' ? true : false"
                  action=""
                  :on-change="elInFile"
                  :limit="1"
                  multiple
                  accept=".jpeg,.jpg,.png"
                  :on-success="onSuccessUploadAddPhoto">
                  <el-button id="btnSelectAddPhoto" size="small" type="primary" @click="upload">
                    选取文件</el-button>
                </el-upload>
              </el-form-item>
              <!-- </div> -->

              <el-form-item label="性别：" prop="sex">
                <el-select v-model="form.sex" placeholder="请选择">
                  <el-option
                    v-for="item in sexList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="院系：" prop="dname">
                <el-select
                  v-model="form.dname"
                  placeholder="请选择"
                  @change="dnameChanged"
                >
                  <el-option
                    v-for="item in systemList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="班级：" prop="cname">
                <el-select v-model="form.cname" placeholder="请先选择系别">
                  <el-option
                    v-for="item in cnameList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>

              <el-form-item label="微信id：" prop="username">
                <el-input v-model="form.username"></el-input>
              </el-form-item>
              <el-form-item label="政治面貌：" prop="political">
                <el-input v-model="form.political"></el-input>
              </el-form-item>

              <el-form-item label="出生日期：" prop="birthday">
                <span class="demonstration"></span>
                <el-date-picker
                  v-model="form.birthday"
                  type="datetime"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div class="dialog-footer" style="text-align: center">
          <el-button @click="cancel()"> 取消 </el-button>
          <el-button
            type="primary"
            @click="dialogStatus == 'create' ? createData() : updateData()"
          >
            确认
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import { getMechanism } from "@/api/resource/index";
// import * as API from "@/api/user";
import * as api from "@/api/users";
import { getHttpUrl } from "@/utils/request";
import { getNodeItemByID } from "@/utils/mine.js";
export default {
  props: ["dialogStatus"],
  data() {
    return {
      returnImgUrl: "",
      props: ["inputName"],
      
      imgBaseUrl: getHttpUrl(),
      imgRightSub: "",

      readonly: true,
      typeList: [],
      idis: "",
      value1: "",
      systemList: [],
      cnameList: [],
      options: [],
      form: {},
      unitList: [],
      groupList: [],
      sexList: [
        {
          id: 0,
          label: "女",
        },
        {
          id: 1,
          label: "男",
        },
      ],
      roleList: [],

      infoFlag: true,
      userTypeList: [],
    };
  },
  created() {
    this.load();
  },
  mounted() {},
  methods: {
    // 点击 网页图片，也能选 图片文件
    toSelectAddPhotoImg(){
      getNodeItemByID("btnSelectAddPhoto").click();
    },
    // 生成一个随机数
    randomNumImgRSub() {
      this.imgRightSub = "?t=" + Math.random().toString(36).slice(2);
    },

    // 上传成功
    onSuccessUploadAddPhoto() {
      this.randomNumImgRSub();
      // 在每一次上传请求结束后清除缓存，否则无法第二次选图
      this.$refs['myUploadAddPhoto'].clearFiles();
    },
    elInFile(f, file) {
      console.log(f.raw, file, "-");
      let formdata = new FormData();
      formdata.append("file", f.raw);
      api.transPhoto(formdata).then((res) => {
        this.returnImgUrl = res.data;
        this.$message({
          showClose: true,
          message: "头像上传成功",
          type: "success",
        });
      });
    },
    upload() {},
    beforeAvatarUpload() {},
    dnameChanged() {
      api
        .getCnameList({
          page: 1,
          pageSize: 10,
        })
        .then((res) => {
          this.cnameList = res.data.records;
        });
    },
    load() {
      api.getCurrUserItem().then((res) => {
        this.idis = res.data.rname;
      });
      // api
      //   .getRoleList({
      //     page: 1,
      //     pageSize: 10,
      //     classnum: ""
      //   })
      //   .then(res => {

      //     this.roleList = res.data.records;
      //   });
      this.roleList = JSON.parse(sessionStorage.getItem("menuList"));
      api
        .getSystemList({
          page: 1,
          pageSize: 1000,
          classnum: "",
          roleid: "",
        })
        .then((res) => {
          this.systemList = res.data.records;
        });
    },
    cancel() {
      this.$emit("changeDialogFormVisible", false);
    },
    getTrue() {
      return true;
    },
    getFalse() {
      return false;
    },
    createData() {
      let r = {};
      r.id = this.form.rname;
      let a = [];
      a.push(r);
      this.form.roles = a;
      this.form.status = 1;
      this.form.roleid = this.form.rname;
      this.form.photo = this.returnImgUrl;
      api.transAddInfo(this.form).then((res) => {
        console.log(res);
        this.$emit("changeDialogFormVisible", false);
      });
    },
    addNew(temp) {
      this.form = temp;
    },
    showData(record) {
      this.form = record;
    },
  },
};
</script>
<style lang="scss" scoped>
.el-col {
  width: 100%;
}
.el-dialog {
  height: 354px;
  width: 669px;
}
.img1 {
  width: 137px;
  height: 164px;
  cursor: pointer;
}
.boxform {
  width: 100%;
  display: flex;
}
</style>
